import React, { useEffect, useRef } from 'react';
import '../Css/counter3.css'; // Ensure this path is correct

const Counter3 = () => {
  const countersRef = useRef([]);

  
  useEffect(() => {
    countersRef.current.forEach(counter => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerText = Math.ceil(count) + ' X'; // Use X instead of +
          setTimeout(updateCount, 10);
        } else {
          counter.innerText = target + ' X'; // Use X instead of +
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="counters-section">
      <div className="counter-box" style={{fontWeight:"bold"}}>
        <div
          className="counter5"
          data-count="1.5"
          ref={el => countersRef.current[0] = el}
        >
          0 X
        </div>
        <p className="counter-text">Increase in doctor’s efficiency using RxOne</p>
      </div>
      <div className="counter-box"style={{fontWeight:"bold"}}>
        <div
          className="counter5"
          data-count="2"
          ref={el => countersRef.current[1] = el}
        >
          <spical>0 X</spical>
        </div>
        <p className="counter-text">Follow-ups increase using RxOne</p>
      </div>
      <div className="counter-box"style={{fontWeight:"bold"}}>
        <div
          className="counter5"
          data-count="3"
          ref={el => countersRef.current[2] = el}
        >
          0 X
        </div>
        <p className="counter-text">New revenue opportunities using RxOne</p>
      </div>
      <div className="counter-box"style={{fontWeight:"bold"}}>
        <div
          className="counter5"
          data-count="1.8"
          ref={el => countersRef.current[3] = el}
        >
          0 X
        </div>
        <p className="counter-text">Improvement in care outcomes using RxOne</p>
      </div>
    </div>
  );
};

export default Counter3;
