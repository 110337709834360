import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/ourPartner.css'; // Custom CSS file for additional styling
import partner1 from '../Images/Picture5.png'; // Path to the first partner image
import partner2 from '../Images/partner1.png'; // Path to the second partner image
import partner3 from '../Images/partner2.png'; // Path to the third partner image
import partner4 from '../Images/partner3.png'; // Path to the fourth partner image
import partner5 from '../Images/forties-removebg-preview.png'; // Path to the fifth partner image
import partner6 from '../Images/partner6.png'; // Path to the sixth partner image
import partner7 from '../Images/Birlaivf.png';


// Array of partner data
const partners = [
  {
    imgSrc: partner1,
  },
  {
    imgSrc: partner2,
  },
  {
    imgSrc: partner3,
  },
  {
    imgSrc: partner4,
  },
  {
    imgSrc: partner5,
  },
  {
    imgSrc: partner6,
  },
  {
    imgSrc: partner7,  
  },
];

// PartnerCard Component to display each partner's image
const PartnerCard = ({ imgSrc }) => {
  return (
    <div className="partner-card">
      <img src={imgSrc} alt="Partner" className="img-fluid partner-img" />
    </div>
  );
};

// OurPartner Component
const OurPartner = () => {
  return (
    <div className="our-partner-container">
      <h2 className="our-partner-heading text-center">Key Partners</h2>
      
      <div className="partners-wrapper">
        <div className="partners-row">
          {partners.map((partner, index) => (
            <PartnerCard key={index} imgSrc={partner.imgSrc} />
          ))}
          {/* Duplicate the row for seamless looping */}
          {partners.map((partner, index) => (
            <PartnerCard key={`duplicate-${index}`} imgSrc={partner.imgSrc} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurPartner;
