import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/digitalMarketing.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif10 from '../Images/Picture15.png';
import serviceGif11 from '../Images/Picture16-removebg-preview.png';
import serviceGif12 from '../Images/Picture17-removebg-preview.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';


// Array of services for the UHX component
const services6 = [
  {
    imgSrc: serviceGif10,
    heading: 'Branded Website',
    description: 'umpstart your digital presence effortlessly with RxOne Market360° plan. Say goodbye to the challenges of creating and managing a website—now, enjoy a branded, ecommerce-enabled, self-service website completely free of charge. Plus, with RxOne, there are no hosting fees to worry about. Get started today and take your online presence to the next level with ease.',
  },
  {
    imgSrc: serviceGif11,
    heading: 'Digital Marketing',
    description: 'Digital marketing is no longer a luxury for medical business. Digital marketing encompasses a wide range of strategies, from search engine optimization (SEO) and content marketing to social media advertising and email marketing.  Through the latest technology and custom strategies, RxOne helps you strengthen the three pillars of marketing success and position your business for growth in the digital age.',
  },
  {
    imgSrc: serviceGif12,
    heading: 'SEO (Search Engine Optimization)',
    description: 'Get your business to appear in local and location-related searches with a Google Business Profile optimized for exactly the right audience to find you. RxOne offers both Off-Page and On-Page SEO, to improve your SERP visibility and rankings with highly optimized product and category pages.',
  },
];

// Component to render UHX services in a structured layout
const AllServices6InRectangle = () => {
  return (
    <div className="service6-rectangle"style={{marginTop:"-5px"}}>
      {services6.map((service6, index) => (
      <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service6-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service6.imgSrc} alt={service6.heading} className="img-fluid service6-img" />
          </div>
          <div className="service6-text">
            <h3 className="mb-3">{service6.heading}</h3>
            <p>{service6.description}</p>
            <a href="#" className="btn btn-light">Book A Demo </a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter9 = () => {
  const countersRef = useRef([]);

useEffect(() => {
  countersRef.current.forEach((counter, index) => {
    const target = +counter.getAttribute('data-count');
    let count = 0;
    const increment = target / 200;
    
    // Set unit as 'X' for the first and last, and 'k' for the second and third
    const unit = (index === 0 || index === 3) ? 'X' : 'k';

    const updateCount = () => {
      if (count < target) {
        count += increment;
        // Adding space between number and unit using &nbsp;
        counter.innerHTML = Math.ceil(count) + '&nbsp;' + unit;
        setTimeout(updateCount, 1);
      } else {
        counter.innerHTML = target + '&nbsp;' + unit;
      }
    };

    updateCount();
  });
}, []);


  return (
    <div className="counters8-section" style={{background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)",}}>
    <div className="Counter7-box">
      <div className="Counter7" data-count="100" ref={el => countersRef.current[0] = el}>
        0 X
      </div>
      <p className="Counter7-text">Earning opportunities through DHIS      </p>
    </div>
    <div className="Counter7-box">
      <div className="Counter7" data-count="2" ref={el => countersRef.current[1] = el}>
        0 X
      </div>
      <p className="Counter7-text">Searchability improvement

      </p>
    </div>
    <div className="Counter7-box">
      <div className="Counter7" data-count="1.5" ref={el => countersRef.current[2] = el}>
        0 %
      </div>
      <p className="Counter7-text">Service geographies expansion


      </p>
    </div>
    <div className="Counter7-box">
      <div className="Counter7" data-count="46" ref={el => countersRef.current[3] = el}>
        0 %
      </div>
      <p className="Counter7-text">Reduction in customer churn

      </p>
    </div>
  </div>
  );
};


// UHX Component combining services and counter
const DigitalMarketing= () => {
  return (
    <div style={{background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)",padding:"3%"}}>
    {/* New Header Section */}
    <div style={{ backgroundColor: '#005C74', height: '8vh', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "7%",marginLeft:"11%" ,borderRadius: '10px',width:"78%" ,}}>
      <h1 style={{ color: '#fff', margin: 0, fontFamily: "initial" }}>Digital with Market360° </h1>
    </div>
  <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop:"-100px" }}>
    <AllServices6InRectangle />
    <Counter9 />
  </div>
  <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
  </div>
  );
};

export default DigitalMarketing;
