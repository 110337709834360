import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/healthcareIot.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif10 from '../Images/p1.jpg';
import serviceGif11 from '../Images/edgedr.png';
import serviceGif12 from '../Images/Picture11.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
// Array of service0s for the UHX component

const services0 = [

  {
    imgSrc: serviceGif10,
    heading: 'RxOne EDGE',
    description: 'RxOne EDGE solution leverages the state-of-the-art multimodal vitals signs monitoring device – “WREN”. The device seamlessly integrates with RxOne EMR offering, thus allowing early prediction and prevention all within EMR solution. Whether patient is at home, being transported through ambulance, or at hospital ICU, his vitals can be tracked simply by accessing WREN – Realtime in RxOne EMR.',
  },
  {
    imgSrc: serviceGif11,
    heading: "Unique Features",
    description:<ul>
      <li>Medical Grade ECG</li>
      <li>Non-invasive Blood Pressure</li>
      <li>SPO2 with Photoplethysmograph</li>
      <li>Respiratory Rate</li>
      <li>Body Temperature</li>
      <li>Mean Atrial Pressure (MAP)
      </li><li>Arrhythmia Detection</li>
      <li>Advanced Alarms & Smart Report</li></ul>
  },
  
  {
    imgSrc: serviceGif12,
    heading: 'Smart Reporting',
    description: "Provide your patients a unique service through detailed analysis and smart reporting through RxOne EMR. Using WREN patients vitals can be captured and a Smart Report can be generated within few seconds, providing critical information around their vitals. Thus, enabling patients to understand the vitals in simple language, and also building a trustable relationship with you.",
  },
];

// Component to render UHX service0s in a structured layout
const Allservices0InRectangle = () => {
  return (
    <div className="service0-rectangle"style={{marginTop:"-5px"}}>
      {services0.map((service0, index) => (
     <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service0-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service0.imgSrc} alt={service0.heading} className="img-fluid service0-img" />
          </div>
          <div className="service0-text">
            <h3 className="mb-3">{service0.heading}</h3>
            <p>{service0.description}</p>
            <a href="#" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter7 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter) => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      const unit = target === 98 || target === 80 ? 'X' : '%'; // Use X for the last two values, otherwise use %

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerHTML = Math.ceil(count) + '&nbsp;' + unit;
          setTimeout(updateCount, 10);
        } else {
          counter.innerHTML = target + '&nbsp;' + unit;
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="counters0-section">
      <div className="Counter7-box">
        <div className="Counter7" data-count="2" ref={(el) => (countersRef.current[0] = el)}>
          0%
        </div>
        <p className="Counter7-text">New revenue opportunities</p>
      </div>
      <div className="Counter7-box">
        <div className="Counter7" data-count="6" ref={(el) => (countersRef.current[1] = el)}>
          0%
        </div>
        <p className="Counter7-text">Multi-Modal Vitals Tracking</p>
      </div>
      <div className="Counter7-box">
        <div className="Counter7" data-count="98" ref={(el) => (countersRef.current[2] = el)}>
          0X
        </div>
        <p className="Counter7-text">Accuracy Levels</p>
      </div>
      <div className="Counter7-box">
        <div className="Counter7" data-count="80" ref={(el) => (countersRef.current[3] = el)}>
          0X
        </div>
        <p className="Counter7-text">Reporting efforts savings</p>
      </div>
    </div>
  );
};


// UHX Component combining services and counter
const HealthcareIot = () => {
  return (
    <div className="headLine" >
      {/* New Header Section */}
      <div className="uppHead" >
        <h1 style={{ color: '#fff', margin: 0, fontFamily: "initial" }}>Healthcare IoT with EDGE
        </h1>
      </div>
    <div className="outsd">
      <Allservices0InRectangle />
      <Counter7/>
    </div>
    <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
    </div>
  );
};


export default HealthcareIot;
