import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/dentalEmr.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif19 from '../Images/Picture24.png';
import serviceGif20 from '../Images/Picture25.png';
import serviceGif21 from '../Images/Picture26-removebg-preview.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
// Array of services for the UHX component
const services9= [
  
  {
    imgSrc: serviceGif19,
    heading: 'Continuous Engagement',
    description: 'The delay in seeking dental treatment is a universal health problem, with a reported prevalence as high as 98%. RxOne helps by educating and encouraging patients to take timely dental treatments. Automated follow-up reminders, medication reminders, health tips and easy to use app to engage patients and to build a trusted relationship with the doctor. ',
  },
  {
    imgSrc: serviceGif20,
    heading: 'Dynamic Workflow',
    description: 'The Dental facilities having more than one department (or chairs), might have different workflows than other OPDs. Hence, RxOne offers Dynamic workflows, which allows several specialists to work in collaboration on a case, create a running Prescription & manage case files together. ',
  },
  {
    imgSrc: serviceGif21,
    heading: 'Specialized EMR',
    description: 'RxOne specialized Dental EMR, provides easy to use Dental charts with Quadrant View and Continuous case sheet features for all appointments for single case. Doctors can easily manage various case files (X-rays, Lab Reports etc) for each appointment and share with patient with a single click. Doctor can also add order items and raise invoice with online/cash payment options',
  },
];

// Component to render UHX services in a structured layout
const AllServices9InRectangle = () => {
  return (
    <div className="service9-rectangle">
      {services9.map((service9, index) => (
     <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service9-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service9.imgSrc} alt={service9.heading} className="img-fluid service9-img" />
          </div>
          <div className="service9-text">
            <h3 className="mb-3">{service9.heading}</h3>
            <p>{service9.description}</p>
            <a href="#" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter12 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter, index) => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      const unit = '%'; // Use percentage for all counters

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerHTML = Math.ceil(count)+'&nbsp;' + unit; // Display percentage
          setTimeout(updateCount, 10); // Adjust the timeout for smoother counting
        } else {
          counter.innerHTML = target +'&nbsp;'+ unit; // Ensure final value has percentage symbol
        }
      };

      updateCount();
    });
  }, []);
 

  return (
    <div className="counters12-section"style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", }}>
      <div className="Counter12-box">
        <div className="Counter12" data-count="44" ref={el => countersRef.current[0] = el}>
          0%
        </div>
        <p className="Counter12-text">Time saved in OPD Consultations        </p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="200" ref={el => countersRef.current[1] = el}>
          0%
        </div>
        <p className="Counter12-text">Increase in follow-ups        </p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="85" ref={el => countersRef.current[2] = el}>
          0%
        </div>
        <p className="Counter12-text">Improvements in patient outcomes        </p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="50" ref={el => countersRef.current[3] = el}>
          0%
        </div>
        <p className="Counter12-text">Operational costs reduction        </p>
      </div>
    </div>
  );
};
// UHX Component combining services and counter
const DentalEmr= () => {
  return (
    <div style={{background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)",padding:"3%"}}>
      {/* New Header Section */}
      <div style={{ backgroundColor: '#005C74', height: '8vh', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "7%",marginLeft:"11%" ,borderRadius: '10px',width:"78%" ,}}>
        <h1 style={{ color: '#fff', margin: 0, fontFamily: "initial" }}>Engage Users – Empower Patients – Enhance Experience </h1>
      </div>
    <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop:"-100px" }}>
      <AllServices9InRectangle />
      <Counter12 />
      
    </div>
    <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
    </div>
  );
};

   
export default DentalEmr;
