import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/forGp.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif13 from '../Images/Picture18.png';
import serviceGif14 from '../Images/Picture19.png';
import serviceGif15 from '../Images/Picture37.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
// Array of services for the UHX component
const services7 = [
  {
    
    imgSrc: serviceGif13,
    heading: 'Consultations Simplified',
    description: "Whether you’d like to connect with your patients In-Person or would like to provide consultation over Video, RxOne simplifies the process through a unified, secured HIPAA compliant interface. What's more, if your patients are outside India, you can still use RxOne EMR to connect with your patients, share prescriptions and case files.",
  },
  {
    imgSrc: serviceGif14,
    heading: 'Custom Rx Templates',
    description: 'Writing digital prescriptions, have never been this easy. Thanks to RxOne custom template feature, that allows you to create custom prescription templates in advance. Thus you can generate state-of-the-art prescriptions, in 11 different Indian languages, just with single click.',
  },
  {
    imgSrc: serviceGif15,
    heading: 'Continuous Engagement',
    description: "Experience unparalleled patient engagement with RxOne's revolutionary autonomous patient engagement engine. From follow-up reminders to prescription messages, medication reminders, and personalized vitals tracking guidelines, our platform automates every step of the patient journey. Stay connected with your patients effortlessly and empower them with valuable health tips",
  },
];

// Component to render UHX services in a structured layout
const AllServices7InRectangle = () => {
  return (
    <div className="service7-rectangle ">
      {services7.map((service7, index) => (
         <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service7-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service7.imgSrc} alt={service7.heading} className="img-fluid service7-img" />
          </div>
          <div className="service7-text">
            <h3 className="mb-3">{service7.heading}</h3>
            <p>{service7.description}</p>
            <a href="#" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter10 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter10) => {
      const target = +counter10.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      const unit = '%'; // Use percentage for all counters

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter10.innerHTML = Math.ceil(count)+ '&nbsp;' + unit; // Display percentage
          setTimeout(updateCount, 10); // Adjust the timeout for smoother counting
        } else {
          counter10.innerHTML = target + '&nbsp;' + unit; // Ensure final value has percentage symbol
        }
      };

      updateCount();
    });
  }, []);


  return (
    <div className="counters10-section"style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)",}}>
      <div className="Counter10-box">
        <div className="Counter10" data-count="44" ref={el => countersRef.current[0] = el}>
          0%
        </div>
        <p className="Counter10-text">Time saved in OPD Consultations        </p>
      </div>
      <div className="Counter10-box">
        <div className="Counter10" data-count="200" ref={el => countersRef.current[1] = el}>
          0%
        </div>
        <p className="Counter10-text">Increase in follow-ups        </p>
      </div>
      <div className="Counter10-box">
        <div className="Counter10" data-count="85" ref={el => countersRef.current[2] = el}>
          0%
        </div>
        <p className="Counter10-text">Improvements in patient outcomes        </p>
      </div>
      <div className="Counter10-box">
        <div className="Counter10" data-count="50" ref={el => countersRef.current[3] = el}>
          0%
        </div>
        <p className="Counter10-text">Operational costs reduction        </p>
      </div>
    </div>
  );
};

// UHX Component combining services and counter
const ForGp = () => {
  return (
    <div style={{background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)",padding:"3%"}}>
      {/* New Header Section */}
      <div style={{ backgroundColor: '#005C74', height: '8vh', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "7%",marginLeft:"11%" ,borderRadius: '10px',width:"78%" ,}}>
        <h1 style={{ color: '#fff', margin: 0, fontFamily: "initial" }}>Connected Care Globally </h1>
      </div>
    <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop:"-100px" }}>
      <AllServices7InRectangle />
      <Counter10 />
    </div>
    <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
    </div>
  );
};

export default ForGp;
