import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/mvt.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif25 from '../Images/Picture30.png';
import serviceGif26 from '../Images/Picture31.png';
import serviceGif27 from '../Images/Picture32.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
// Array of services for the UHX component
const services11= [
  
  {
    imgSrc: serviceGif25,
    heading: 'Beyond Geographies',
    description: 'Expand Care Services beyond geographical boundaries with RxOne’s unique MVT offerings. The RxOne platform delivers an unparalleled care experience through its All-in-One “RxOne-Care to Cure” App, which includes treatment advisory, appointment scheduling, teleconsultations, health file management, and visa, travel, and logistics assistance. This solution enables care providers to engage with patients via a state-of-the-art interface, ensuring an excellent user experience.',
  },
  {
    imgSrc: serviceGif26,
    heading: 'Beyond HIS/HMS',
    description: 'Once the intervention is completed for International Patients, the data in HMS/HIS system remains unusable foreseeable future. With RxOne smart integrations, the HMS/HIS can exchange the data continuously with patients, giving them required transparency and building long lasting trustable relationship. ',
  },
  {
    imgSrc: serviceGif27,
    heading: 'Care Continuum',
    description: 'Most often the engagement with International Patient is over shortly after the treatment. However, as a patient, the care to cure journey continues even after patient is back to home country. With RxOne, Care providers can offer care continuum for International Patients with Post-Op follow ups, access medical files such as tests results remotely, see body vitals trend and provide Tele consultations, all this enhances patient experience and improve care outcomes.',
  },
];

// Component to render UHX services in a structured layout
const Allservices11InRectangle = () => {
  return (
    <div className="service11-rectangle">
      {services11.map((service11, index) => (
        <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service11-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service11.imgSrc} alt={service11.heading} className="img-fluid service11-img" />
          </div>
          <div className="service11-text">
            <h3 className="mb-3">{service11.heading}</h3>
            <p>{service11.description}</p>
            <a href="#" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};




// UHX Component combining services and counter
const Mvt= () => {
  return (
    <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "3%" }}>
      {/* New Header Section */}
      <div style={{ backgroundColor: '#005C74', height: '8vh', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "7%",marginLeft:"11%" ,borderRadius: '10px',width:"78%" ,}}>
        <h1 style={{ color: '#fff', margin: 0, fontFamily: "initial" }}>Care Continuum Globally        </h1>
      </div>
      <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop:"-100px" }}>
  <Allservices11InRectangle />
</div>
<div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
    </div>
  );
};

export default Mvt;
