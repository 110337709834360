import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "../Css/Header.css";
import header__logo from "../Images/logo3.png";
import header_logo2 from '../Images/rxOne.png'; // Scrolled logo
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Login from "./user/Login";
import ServiceCall from "../ServiceCall";

function Header() {
  const navigate = useNavigate();
  const [islogin, setlogin] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [nav, isNav] = useState(false);
  const [activeLogo, setActiveLogo] = useState(header__logo); // Default logo
  const [specialties, setSpecialties] = useState([]); // Initialize specialties
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showMobileProductsDropdown, setShowMobileProductsDropdown] = useState(false);
  const [showMobileSolutionsDropdown, setShowMobileSolutionsDropdown] = useState(false);
  const [swap, setSwap] = useState("Download App"); // Initialize swap

  const transitionNav = () => {
    if (window.scrollY > 100) {
      isNav(true);
      setActiveLogo(header_logo2); // Change logo when scrolled
    } else {
      isNav(false);
      setActiveLogo(header__logo); // Default logo
    }
  };

  useEffect(() => {
    if (!islogin) {
      document.body.style.overflowY = "scroll";
    }
    window.addEventListener("scroll", transitionNav);
    return () => window.removeEventListener("scroll", transitionNav);
  }, [islogin]);

  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        const response = await ServiceCall.getSpecialties(); // Replace with your service call
        setSpecialties(response.data); // Update specialties with fetched data
      } catch (error) {
        console.error('Error fetching specialties:', error);
      }
    };

    fetchSpecialties(); // Fetch specialties on component mount
  }, []);

  const toggleMobileMenu = () => {
    setMobile(!isMobile);
  };

  const toggleMobileProductsDropdown = () => {
    setShowMobileProductsDropdown(prevState => !prevState);
    setShowMobileSolutionsDropdown(false); // Close the other dropdown
  };

  const toggleMobileSolutionsDropdown = () => {
    setShowMobileSolutionsDropdown(prevState => !prevState);
    setShowMobileProductsDropdown(false); // Close the other dropdown
  };
  const handleItemClick = () => {
    setShowDropdown(false);
  };

  const handleMobileItemClick = () => {
    setMobile(false);
    setShowMobileProductsDropdown(false);
    setShowMobileSolutionsDropdown(false);
  };

  return (
    <>
      <header
        className={` header ${nav && "header__color"} ${
          isMobile && "header__color"
        } ${islogin && "login__container"}  ms-main-header`}
      >
        {islogin && <Login />}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg px-0">
                {/* Logo */}
                <NavLink className="navbar-brand" to="/">
                  <img src={activeLogo} alt="Logo" />
                </NavLink>
                {/* Navbar */}
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="hamburgernavmenucontent"
                >
                  <ul className="navbar-nav align-items-lg-center">
                    <li className="nav-item ms-line-slide">
                      <a href="https://rxone.app/" className="nav-link" 
                          style={{
                            color: nav || isMobile ? "#000" : "#fff",
                          }}>
                        For Patients
                      </a>
                    </li>
                    <li>
                      <div
                        className="dropdown-menu-v2"
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="dropdown-toggle"
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            marginRight: "1.3rem",
                            cursor: "pointer",
                           color: nav || isMobile ? "#000" : "#fff"
                          }}
                        >
                          Products
                        </div>
                        {showDropdown && (
                          <ul
                            className="dropdown-list"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              backgroundColor: "#fff",
                              listStyle: "none",
                              padding: "10px 0",
                              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                              borderRadius: "8px",
                              zIndex: 100,
                            }}
                          >
                            <li className="dropdown-item" style={{ padding: "10px 20px" }}               onClick={handleItemClick}
                            >
                              <NavLink
                                to="/Next_Gen_AI_powered_EMR"
                                style={{ textDecoration: "none", color: "#000" }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                EMR
                              </NavLink>
                            </li>
                            <li className="dropdown-item" style={{ padding: "10px 20px" }} onClick={handleItemClick}>
                              <NavLink
                                to="/Healthcare_IoT_with_EDGE"
                                style={{ textDecoration: "none", color: "#000" }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                EDGE
                              </NavLink>
                            </li>
                            <li className="dropdown-item" style={{ padding: "10px 20px" }} onClick={handleItemClick}>
                              <NavLink
                                to="/Unified_Health_Exchange"
                                style={{ textDecoration: "none", color: "#000" }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                UHX
                              </NavLink>
                            </li>
                            <li className="dropdown-item" style={{ padding: "10px 20px" }} onClick={handleItemClick}>
                              <NavLink
                                to="/Digital_with_Market360"
                                style={{ textDecoration: "none", color: "#000" }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                Market 360*
                              </NavLink>
                            </li>
                          </ul>
                        )}
                      </div>
                    </li>
                    <li>
                      <div
                        className="dropdown-menu-v2"
                        onMouseEnter={() => setShowDropdown1(true)}
                        onMouseLeave={() => setShowDropdown1(false)}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="dropdown-toggle"
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            marginRight: "10px",
                            cursor: "pointer",
                            color: nav || isMobile ? "#000" : "#fff",
                          }}
                        >
                          Solutions
                        </div>
                        {showDropdown1 && (
                          <ul
                            className="dropdown-list"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              backgroundColor: "#fff",
                              listStyle: "none",
                              padding: "10px 0",
                              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                              borderRadius: "8px",
                              zIndex: 100,
                            }}
                          >
                            <li className="dropdown-item" style={{ padding: "10px 20px" }} onClick={handleItemClick}>
                              <NavLink to="/Specialized_EMR_for_GPs">For GPs</NavLink>
                            </li>
                            <li className="dropdown-item" style={{ padding: "10px 20px" }} onClick={handleItemClick}>
                              <NavLink to="/Specialized_Cardiology_EMR">For Cardiologists</NavLink>
                            </li>
                            <li className="dropdown-item" style={{ padding: "10px 20px" }} onClick={handleItemClick}>
                              <NavLink to="/Specialized_Dental_EMR">For Dentists</NavLink>
                            </li>
                            <li className="dropdown-item" style={{ padding: "10px 20px" }} onClick={handleItemClick}>
                              <NavLink to="/Specialized_Pediatric_EMR">For Pediatricians</NavLink>
                            </li>
                            <li className="dropdown-item" style={{ padding: "10px 20px" }} onClick={handleItemClick}>
                              <NavLink to="/Medical_Value_Tourism_Enablement">For MVT</NavLink>
                            </li>
                          </ul>
                        )}
                      </div>
                    </li>
                    <li className="nav-item ms-line-slide" onClick={handleItemClick}>
                      <NavLink activeclassname="active" to="/aboutus" style={{color: nav || isMobile ? "#000" : "#fff"}}>
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item ms-line-slide" onClick={handleItemClick}>
                      <NavLink activeclassname="active" to="/blogs" style={{color: nav || isMobile ? "#000" : "#fff"}}>
                        Blog
                      </NavLink>
                    </li>
                    <li className="nav-item" >
                      <a className="ms-secondary-btn ms-header-btn" href="http://www.tosto.re/RxOne">
                        {swap}
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Mobile Menu */}
                <div
                  className={`menu__toggle__content ${
                    !isMobile && "res__display"
                  }`}
                >
                  <ul>
                  <li >
                      <a href="https://rxone.app/" className="nav-link">
                        For Patients
                      </a>
                    </li>
                    <li>
                      <div
                            onClick={() => {
                              toggleMobileProductsDropdown();
                              if (showMobileProductsDropdown) handleMobileItemClick();
                            }}
                        
                        className="dropdown-toggle"
                        style={{
                          fontSize: "17px",
                          fontWeight: "600",
                          marginRight: "20px",
                          marginLeft: "18px",
                          cursor: "pointer",
                        }}
                      >
                        Products
                      </div>
                      {showMobileProductsDropdown && (
                        <ul className="mobile-dropdown-list">
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to="/Next_Gen_AI_powered_EMR">
                              EMR
                            </NavLink>
                          </li>
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to="/Healthcare_IoT_with_EDGE">
                              EDGE
                            </NavLink>
                          </li>
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to="/Unified_Health_Exchange">
                              UHX
                            </NavLink>
                          </li>
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to="/Digital_with_Market360">
                             MARKET 360
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <div
                          onClick={() => {
                            toggleMobileSolutionsDropdown();
                            if (showMobileSolutionsDropdown) handleMobileItemClick();
                          }}
                      
                        className="dropdown-toggle"
                        style={{
                          fontSize: "17px",
                          fontWeight: "600",
                          marginRight: "20px",
                          marginLeft: "18px",
                          cursor: "pointer",
                        }}
                      >
                        Solutions
                      </div>
                      {showMobileSolutionsDropdown && (
                        <ul className="mobile-dropdown-list">
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to="/Specialized_EMR_for_GPs">
                              GPS
                            </NavLink>
                          </li>
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to="/Specialized_Cardiology_EMR">
                              CARDIOLOGIST
                            </NavLink>
                          </li>
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to="/Specialized_Dental_EMR">
                              DENTIST
                            </NavLink>
                          </li>
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to="/Specialized_Pediatric_EMR">
                             PEDIATRICIANS
                            </NavLink>
                          </li>
                          <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                            <NavLink to='/Medical_Value_Tourism_Enablement'>
                            MVT
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                 
                    <li>
                      <NavLink activeclassname="active" to="/aboutus" onClick={handleMobileItemClick}>
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeclassname="active" to="/blogs" onClick={handleMobileItemClick}>
                        Blog
                      </NavLink>
                    </li>
                    <li>
                      <a
                        style={{ padding: "6px 40px", fontSize: "14px" }}
                        className="ms-secondary-btn ms-header-btn" 
                        href="http://www.tosto.re/RxOne"
                      >
                        {swap}
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="menu_toggle"
                  onClick={toggleMobileMenu}
                >
                  {isMobile ? (
                    <CloseIcon fontSize="medium" />
                  ) : (
                    <MenuIcon fontSize="medium" />
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;