import React, { useEffect, useRef } from 'react';
import '../Css/counter.css'; // Ensure this path is correct


const Counter = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach(counter => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerText = Math.ceil(count) + '+'; // Add plus sign
          setTimeout(updateCount, 1);
        } else {
          counter.innerText = target + '+'; // Add plus sign
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="counters-section" style={{backgroundColor:"white", margin:"-75px auto 0 auto"}}>
      <div className="counter-box" style={{   background: 'linear-gradient(45deg, #005C74, #018C92, #01A09E)', width: "20%", }}>
        <div
          className="counter"
          data-count="200"
          ref={el => countersRef.current[0] = el}
        >
          0+
        </div>
        <p className="counter-text">Care Facilities</p>
      </div>
      <div className="counter-box" style={{   background: 'linear-gradient(45deg, #005C74, #018C92, #01A09E)',width: "20%" }}>
        <div
          className="counter"
          data-count="9000"
          ref={el => countersRef.current[1] = el}
        >
          0+
        </div>
        <p className="counter-text">Happy Patients</p>
      </div>
      <div className="counter-box"style={{   background: 'linear-gradient(45deg, #005C74, #018C92, #01A09E)', width: "20%"}}>
        <div
          className="counter"
          data-count="11500"
          ref={el => countersRef.current[2] = el}
        >
          0+
        </div>
        <p className="counter-text">Consultations</p>
      </div>
      <div className="counter-box" style={{   background: 'linear-gradient(45deg, #005C74, #018C92, #01A09E)', width: "20%"}}>
        <div
          className="counter"
          data-count="15000"
          ref={el => countersRef.current[3] = el}
        >
          0+
        </div>
        <p className="counter-text">Digital Records</p>
      </div>
    </div>
  );
};

export default Counter;
