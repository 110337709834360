import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/pediatrEmr.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif22 from '../Images/Picture27.png';
import serviceGif23 from '../Images/Picture28.png';
import serviceGif24 from '../Images/Picture29.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
// Array of services for the UHX component
const services10= [
  {
    
    imgSrc: serviceGif22,
    heading: 'Smart Calendar',
    description: 'In 2023, approximately 14.5 million children worldwide missed out on essential vaccinations, becoming what are known as “zero-dose children.” RxOne is addressing this critical issue with its Smart Calendar feature, which allows specialists to set up automated reminders for vaccinations, treatments, and more. These reminders ensure that parents are notified in a timely manner, helping to guarantee that their children receive necessary vaccinations on schedule.',
  },
  {
    imgSrc: serviceGif23,
    heading: 'Smart Prescriptions',
    description: "Tired of creating prescription for every patient? RxOne here to help, save template prescriptions in advance, so that you don’t need to fill all the attributes for every patient. With RxOne, you get contextual autosuggest, advice schedule tables, vitals chart, multilingual prescription and such 9 different features. What's more, RxOne automatically creates reminders for all medications advised, so your patients never miss a dose.",
  },
  {
    imgSrc: serviceGif24,
    heading: 'Smart Engagement',
    description: 'Don’t want to use your personal phone number for OPD interactions? Not-to-worry, use RxOne’s Patient Messaging to interact with patients securely and only for the time you have allowed them to send messages. RxOne, also offers Smart QR Kiosk and Booking Widget, which allows patients to register, book appointment, payments and handles cancellations & refunds autonomously.',
  },
];

// Component to render UHX services in a structured layout
const AllServices10InRectangle = () => {
  return (
    <div className="service10-rectangle">
      {services10.map((service10, index) => (
        <div className="service10-section" key={index}>
          <div className={`service10-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service10.imgSrc} alt={service10.heading} className="img-fluid service10-img" />
          </div>
          <div className="service10-text">
            <h3 className="mb-3">{service10.heading}</h3>
            <p>{service10.description}</p>
            <a href="#" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter13 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter, index) => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      const unit = '%'; // Use percentage for all counters

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerHTML = Math.ceil(count)+'&nbsp;' + unit; // Display percentage
          setTimeout(updateCount, 10); // Adjust the timeout for smoother counting
        } else {
          counter.innerHTML = target +'&nbsp;'+ unit; // Ensure final value has percentage symbol
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="counters13-section" style={{background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)",}}>
      <div className="Counter13-box">
        <div className="Counter13" data-count="44" ref={el => countersRef.current[0] = el}>
          0%
        </div>
        <p className="Counter13-text">Time saved in OPD Consultations </p>
      </div>
      <div className="Counter13-box">
        <div className="Counter13" data-count="200" ref={el => countersRef.current[1] = el}>
          0%
        </div>
        <p className="Counter13-text">AIncrease in follow-ups </p>
      </div>
      <div className="Counter13-box">
        <div className="Counter13" data-count="65" ref={el => countersRef.current[2] = el}>
          0%
        </div>
        <p className="Counter13-text">Medication adherence improvements </p>
      </div>
      <div className="Counter13-box">
        <div className="Counter13" data-count="50" ref={el => countersRef.current[3] = el}>
          0%
        </div>
        <p className="Counter13-text">Operational costs reduction</p>
      </div>
    </div>
  );
};

// UHX Component combining services and counter
const PediatrEmr= () => {
  return (
    <div style={{background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)",padding:"3%"}}>
      {/* New Header Section */}
      <div style={{ backgroundColor: '#005C74', height: '8vh', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "7%",marginLeft:"11%" ,borderRadius: '10px',width:"78%" ,}}>
        <h1 style={{ color: '#fff', margin: 0, fontFamily: "initial" }}>Engage Users – Empower Patients – Enhance Experience        </h1>
      </div>
    <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop:"-100px"}}>
      <AllServices10InRectangle />
      <Counter13 />
    </div>
    <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
    </div>
  );
};

export default PediatrEmr;
