import React from 'react';
import '../Css/careFacility.css'; // Import the corresponding CSS
import careFacilityImage from '../Images/edgedr.png';
import careFacilityImage2 from '../Images/fourdr.png'; 
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Style } from '@material-ui/icons';

const CareFacility = () => {
  
  const navigate = useNavigate('');

  const handleclick = () =>{
    navigate('/Next_Gen_AI_powered_EMR')

  }
  const handleclick2 = () =>{
    navigate('/Healthcare_IoT_with_EDGE')

  }
  const handleclick3 = () =>{
    navigate('/Unified_Health_Exchange')

  }
  const handleclick4 = () =>{
    navigate('/Digital_with_Market360')

  }

  const isMobile = useMediaQuery("(max-width:710px)");

    return (
        <>
        <div className="care-facility-container">
            <div className="header-section" style={{padding:"1%",marginBottom:"1%",marginLeft:"6%"}}>
                <h1 className="header-title">Care Facility Management, Simplified <span className="emr-highlight">EMR</span></h1>
            </div>
            <div className="box-container">
                <div className="box">
                    <h3 className="box-title">Patient Engagement</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">OPD Management</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">Smart Calendar</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">Smart Prescriptions</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">Telehealth & Remote Monitoring</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">Medicines & Lab Tests</h3>
                </div>
            </div>

            <div className="button-container" style={{marginTop:"-2%"}}>
            <a href="https://calendly.com/rxonecare" target="_blank" rel="noopener noreferrer">
    <button className="btn-book-demo">Book A Demo</button>
</a>
                <button className="btn-know-more" onClick={handleclick}>Know More</button>
             
            </div>
            <div className="banner-container">
  <div style={{marginTop:"12%",width:"60%",marginLeft:"3%"}}>
    <h1 className="header-title2"style={{fontSize: isMobile ? "32px" : "2rem",fontSize:"3rem"}}>
      Patient Outcomes, Improvised: <span className="emr-highlight">EDGE</span>
    </h1>
  </div>

  <div className="text-image-section"style={{padding:"5%"}}>
    <div className="text-content">
      <p className="text-description">
        Use AI powered Smart Reports, Medical Records Summarization and Remote Monitoring to gain actionable insights and improve diagnostic accuracy.
      </p>
      <div className="button-container2"style={{paddingLeft:"1%"}}>
    <a href="https://calendly.com/rxonecare" target="_blank" rel="noopener noreferrer">
      <button className="btn-book-demo">Book A Demo</button>
    </a>
    <button className="btn-know-more" onClick={handleclick2}>Know More</button>
  </div>
    </div>
    <div className="image-content">
      <img src={careFacilityImage} alt="Care Facility" className="care-facility-image" />
    </div>
  </div>

 
</div>
            <div className="header-section3">
                <h1 className="header-title3"style={{padding:"2.5%"}}>Health Data Exchange, Streamlined <span className="emr-highlight">UHX</span></h1>
            </div>
            <div className="box-container2">
                <div className="box2">
                    <h3 className="box-title2">ABHA ID Creation  </h3>
                </div>
                <div className="box2">
                    <h3 className="box-title">Facility ABDM Registration </h3>
                </div>
                <div className="box2">
                    <h3 className="box-title">Unified Health Interchange </h3>
                </div>
                <div className="box2">
                    <h3 className="box-title">Digital Health Incentive Scheme </h3>
                </div>
               
            </div>
            <div className="box-container2 ">
            <a href="https://calendly.com/rxonecare" target="_blank" rel="noopener noreferrer">
    <button className="btn-book-demo">Book A Demo</button>
</a>
<button className="btn-know-more" onClick={handleclick3}>Know More</button>
            </div>

            <div className="banner-container">
  <div style={{marginTop:"12%",width:"60%",marginLeft:"5%"}}>
    <h1 className="text-heading5">
    Digital Marketing, Unified: 
    <span className="emr-highlight">Market360*</span>
    </h1>
  </div>

  <div className="text-image-section"style={{padding:"5%"}}>
    <div className="text-content">
      <p className="text-description2">
      Scale your care services with end-to-end digital marketing and digital presence management. Branded website, SEO, Lead Generation, Social Media management and much more.

      </p>
      <div className="button-container5"style={{marginLeft:"-10rem"}}>
    <a href="https://calendly.com/rxonecare" target="_blank" rel="noopener noreferrer">
      <button className="btn-book-demo">Book A Demo</button>
    </a>
    <button className="btn-know-more" onClick={handleclick4}>Know More</button>
  </div>
    </div>
    <div className="image-content2">
      <img src={careFacilityImage2} alt="Care Facility" className="care-facility-image" />
    </div>
  </div>

 
</div>
        </div>
        </>
    );
}

export default CareFacility;
