import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/beforeFooter.css'; // Ensure this CSS file is imported


const BeforeFooter = () => {
  return (
    <div className="before-footer-container">
      <div className="before-footer-content">
        <span className="before-footer-text">Get FREE Demo today!</span>
        <a href="https://calendly.com/rxonecare" target="_blank" rel="noopener noreferrer">
          <button className="before-footer-button">Book A Demo</button>
        </a>
      </div>
    </div>
  );
};

export default BeforeFooter;
