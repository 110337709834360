import React from 'react';
import '../Css/home2.css'; // Import the corresponding CSS
import Header from './Header';
// Import icons from MUI
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Counter from './Counter';
import OurPartner from './OurPartner';
import CareFacility from './CareFacility';
import Testimonials from './Testimonials';
import Counter3 from './Counter3';
import BeforeFooter from './BeforeFooter';
// Import images
import image1 from '../Images/Picture33-removebg-preview.png';
import image2 from '../Images/Picture34-removebg-preview.png';
import image3 from '../Images/Picture35-removebg-preview.png';
import halfBgImage from '../Images/Doctor_Portal_Mockup.png';

function Home2() {
  return (
    <>
    <Header/>
    <div className="home2-container">
      <div className="gradient-section">
        <div className="content-section">
          <h1 className="heading" style={{marginTop: "5rem"}}>Scale services, optimize costs and improve care outcomes</h1>
          <div className="button-container">
            <div style={{backgroundColor:"#005C74",border: "none",borderradius: "5px",}}>
          <a href="https://calendly.com/rxonecare" target="_blank" rel="noopener noreferrer">
    <button className="btn-download">Book A Demo</button>
</a></div>
            <a href="https://play.google.com/store/apps/details?id=com.rxonedoctor&hl=en_IN" target="_blank" rel="noopener noreferrer">
    <button className="btn-download">Download App</button>
</a>

          </div>
          <div className="description" style={{color:"#fff",}}>
            Our EMR solution leverages the world’s leading AI models and IoT device integrations to seamlessly automate care workflows, provide real-time decision support, and optimize revenue. This allows you to focus on what matters most—your patients—and enhance the quality of care you deliver.
          </div>
          <div className="icon-row">
            <div className="icon-item">
              <p className="icon-text"><LaptopMacIcon/> Web App</p>
            </div>
            <div className="icon-item">  
              <p className="icon-text"><TabletMacIcon/> IOS & Android</p>
            </div>
            <div className="icon-item">
              <p className="icon-text"><PhoneIphoneIcon/> iPad</p>
            </div>
          </div>
          
        </div>
      </div>
      
      <div className="image-section">
        <img src={halfBgImage} alt="Decorative" className="half-bg-image" />
      </div>
      
      {/* New image section added */}
      {/* <div className="image-container">
        <img src={image1} alt="Image 1" className="image image1" />
        <img src={image2} alt="Image 2" className="image image2" />
        <img src={image3} alt="Image 3" className="image image3" />
      </div>
       */}
    </div>
    <Counter/>
    <OurPartner/>
    <CareFacility/>
    <Testimonials/>
    <Counter3/> 
    <BeforeFooter/>
    </>
  );
 
}


export default Home2;
