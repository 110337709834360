import React, { useEffect, useRef } from 'react';
import '../Css/testimonials.css'; // Ensure the path is correct
import doctorImage from '../Images/Picture36.png'; // Update the path as needed

const Testimonials = () => {
  const counterRef = useRef(null);

  useEffect(() => {
    
    const target = +counterRef.current.getAttribute('data-count');
    let count = 0;
    const increment = target / 200;

    const updateCount = () => {
      if (count < target) {
        count += increment;
        counterRef.current.innerText = Math.ceil(count) + '% NPS';
        setTimeout(updateCount, 10);
      } else {
        counterRef.current.innerText = target + '% NPS';
      }
    };

    updateCount();
  }, []);

  return (
    <div className="testimonials-page-container">
      {/* Separate container for the heading and description */}
      <div className="header-container">
        <h3 className="testimonials-heading">Testimonials</h3>
        <p className="testimonials-description">
          Hear from experts, what they say about RxOne
        </p>
      </div>

      <div className="testimonials-container">
        {/* Left Box: Doctor Information with Image */}
        <div className="left-content">
          <div className="testimonial-box">
            <img src={doctorImage} alt="Doctor" className="doctor-image" />
            <div className="doctor-info">
              <p className="doctor-name">Dr. Himani Narula</p>
              <p className="doctor-post">Developmental Child Pediatrician</p>
              <p className="testimonial-text">
                "By far the most advanced EMR available in the market. It used to take hours analyzing patient reports, which has now turned into minutes. All thanks to RxOne technology."
              </p>
            </div>
          </div>
        </div>

        {/* Right Box: Counter with Description */}
        <div className="right-content"style={{height:"150%"}}>
          <div className="New-box">
            <div style={{padding:"1%"}}>
            <h2 className="counter" data-count="87" ref={counterRef}>
              0% NPS
              
            </h2>
            </div>
            <div style={{fontFamily:"initial"}}>
            <p className="New-box-description">
              Most of our users recommend the solution to fellow care providers.
            </p>
            </div>
          </div>
        </div>
        
      </div>
      <div className="header-section">
                <h1 className="header-title" style={{textAlign: "center"}}>Why use RxOne? </h1>
            </div>
      {/* <div style={{display:"flex",justifyItems:"center"}}><h1>why to use rxone?</h1></div> */}
      
    </div>
  );
};

export default Testimonials;
